<template>
  <el-container>
    <el-main>
      <el-container>
        <el-aside width="40%">
          <el-tabs value="first" style="outline: 0;">
            <el-tab-pane label="新增组信息" name="first">
              <el-form ref="groupRef" :rules="rules" :model="groupForm" label-width="100px" size="small">
                <el-form-item label="组代码" prop="groupCode">
                  <el-input placeholder="组代码" v-model="groupForm.groupCode" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="组名称" prop="groupName">
                  <el-input placeholder="组名称" v-model="groupForm.groupName" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="是否启用">
                  <el-switch v-model="groupForm.isEnabled" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="描述" prop="groupDesc">
                  <el-input type="textarea" v-model="groupForm.groupDesc"></el-input>
                </el-form-item>
                <!--<el-form-item>
                  <el-button type="primary" @click="onSubmit('groupRef')">立即创建</el-button>
                  <el-button @click="drawer.status = false">取消</el-button>
                </el-form-item>-->
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
          <el-tabs value="first" style="outline: 0;">
            <el-tab-pane label="角色信息" name="first">
              <!--<el-tree
                  :data="[]"
                  show-checkbox
                  node-key="id">
              </el-tree>-->
              <!--              <Role/>-->
              <el-table
                  :data="role.tableData"
                  :height="role.tableHeight"
                  border
                  size="small"
                  style="width: 100%;"
                  v-loading="role.loading"
                  ref="topictable"
              >
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="roleCode"
                    label="角色代码"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="roleName"
                    label="角色名称"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="isRoleEnabled"
                    label="是否激活"
                    :formatter="formatIsEnabled"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="createdBy"
                    label="创建人"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="lastModifyTime"
                    label="修改时间"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                    prop="lastModifyBy"
                    label="修改人"
                    show-overflow-tooltip>
                </el-table-column>
              </el-table>

              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="role.currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="role.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="role.pageTotal"
                  style="margin-top: 10px">
              </el-pagination>


            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-main>
    <el-footer>
      <el-button type="primary" size="small" @click="onSubmit('groupRef')">立即创建</el-button>
      <el-button size="small" @click="drawer.status = false">取消</el-button>
    </el-footer>
  </el-container>

</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  props: {
    drawer: Object,
    findByPage: Function
  },
  data() {
    return {
      groupForm: {
        groupName: '',
        groupCode: '',
        groupDesc: '',
        isEnabled: '1',
        groupRoleList: []
      },
      rules: {
        groupName: [
          {required: true, message: '请填写组名称', trigger: 'blur'}
        ],
        groupCode: [
          {required: true, message: '请填写组代码', trigger: 'blur'}
        ],
        groupDesc: [
          {required: true, message: '请填写组描述', trigger: 'blur'}
        ],
      },
      role: {
        tableData: [],
        multipleSelection: [],
        currentPage: 1,
        pageSize: 10,
        loading: true,
        pageTotal: 0,
        tableHeight: null,
      }
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.role.pageSize = val
      this.findRoleByPage()
    },
    handleCurrentChange(val) {
      this.role.currentPage = val
      this.findRoleByPage()
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs.topictable.store.states.selection.forEach(row => {
            this.groupForm.groupRoleList.push(row.id)
          })

          return request({
            method: 'post',
            url: '/masterdata/sysGroup/createSysGroup',
            contentType: 'application/json',
            data: this.groupForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            // eslint-disable-next-line no-empty
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '创建成功',
                type: 'success',
              });

              this.$refs[formName].resetFields();
              this.drawer.status = false
              this.findByPage()
            }
          })
        }
      })
    },
    formatIsEnabled: function (row) {
      return row.isRoleEnabled === 1 ? '是' : row.isRoleEnabled === 0 ? '否' : '未知'
    },
    findRoleByPage() {
      this.role.loading = true

      return request({
        url: `/masterdata/sysRole/findByPage/${this.role.currentPage}/${this.role.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: {}
      }).then((response) => {
        this.role.tableData = response.data.content;
        this.role.pageTotal = response.data.totalElements;
        this.role.loading = false;
      })
    },
  }, created() {
    this.findRoleByPage()
  }, mounted() {
    this.role.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 230;

  }
}
</script>

<style scoped>
.el-footer {
  /*background-color: #B3C0D1;*/
  color: #333;
  text-align: right;
}

.el-aside {
  color: #333;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-main {
  color: #333;
}

.el-container {
  /*padding: 20px;*/
  height: 100% !important;
}

.el-tree-node__content {
  height: 30px !important;
}
</style>
